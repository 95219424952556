<template>
  <div v-if="item">
    <div class="quiz-item position-relative pointer h-100" v-if="viewList">
      <LockFile v-if="!item.is_free"/>
      <img v-if="item.label" :src="item.label" alt="" class="label-item">
      <div class="quiz-name display-html display-2-line" :title="item.name" @click.prevent="redirectDetail">{{item.name}}</div>
      <div class="quiz-line"></div>
      <div class="quiz-subtitle row">
        <div class="col-8">
          <span>{{item.class_name}}</span> &#8226; <span>{{item.category_name}}</span>
        </div>
        <div class="col-4 quiz-action">
          <div class="text-right" v-if="!action">
            <template v-if="!user">
              <img src="/icon/library/save-video.svg" class="save-video" v-b-modal.login/>
              <span class="sub-title">Lưu</span>
            </template>
            <template v-if="item.is_saved">
              <img src="/icon/library/save-video-active.svg" class="save-video" @click.prevent="redirectDetail"/>
              <span class="sub-title">Đã lưu</span>
            </template>
            <template v-else>
              <img src="/icon/library/save-video.svg" class="save-video" @click.prevent="redirectDetail"/>
              <span class="sub-title">Lưu</span>
            </template>
          </div>
          <div class="text-right" v-else>
            <template v-if="!user">
              <img src="/icon/library/save-video.svg" class="save-video" v-b-modal.login/>
              <span class="sub-title">Lưu</span>
            </template>
            <template v-else-if="item.is_saved">
              <img src="/icon/library/save-video-active.svg" class="save-video" @click.prevent="UnSave"/>
              <span class="sub-title">Đã lưu</span>
            </template>
            <b-dropdown no-caret v-else :id="'dropdown-save-quiz-'+item.id" :ref="'dropdownSaveQuiz'+item.id" right variant="tranparent p-0" class="dropdown-save-quiz">
              <template #button-content>
                <img src="/icon/library/save-video.svg" class="save-video"/>
                <span class="sub-title">Lưu</span>
              </template>
              <div class="title">Lưu vào thư viện</div>
              <img class="close" src="/icon/library/close.svg" @click.prevent="$refs['dropdownSaveQuiz'+item.id].hide(true)"/>
              <div class="row row-custom row-padding-15 playlist-quiz scrollbar">
                <div class="col-12" v-for="value in playlist" :key="value.id">
                  <b-form-checkbox v-model="playlistId" :value="value.id" >{{value.name}}</b-form-checkbox>
                </div>
              </div>
              <div class="row row-custom row-padding-15 playlist-quiz">
                <div class="col-12 new-playlist">
                  Tạo danh sách mới
                </div>
                <div class="col-12">
                  <input class="playlist-name form-control" placeholder="Tên danh sách" v-model="playlistName"/>
                  <div v-if="textError" class="text-error">{{textError}}</div>
                </div>
              </div>
              <div class="action-new-playlist">
                <button class="btn" @click.prevent="createPlaylist">Tạo danh sách</button>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="quiz-action row row-custom row-padding-10 gutters-custom align-items-center">
        <div class="col-5">
          <div class="d-flex align-items-center"><img src="/icon/library/time.svg" /> <span class="sub-title">{{ item.time }} phút</span></div>
        </div>
        <div class="col-7">
          <div class="d-flex align-items-center justify-content-end"><img src="/icon/library/question.svg" /> <span class="sub-title">{{ item.number_question }} câu</span></div>
        </div>
        <div class="col-12 quiz-source">
          Nguồn: {{ item.author }}
        </div>
        <!-- <div class="col-6 quiz-source text-truncate">
          {{ item.number_people }} người đã làm
        </div> -->
      </div>
    </div>
    <div class="quiz-item-detail" v-else>
      <div class="form-media-data">
        <div class="content">
          <div class="d-flex align-items-center pointer" :title="item.name" @click.prevent="redirectDetail">
            <div class="quiz-name display-html ">{{item.name}}</div>
            <img v-if="!item.is_free" class="lock" src="/icon/library/lock.svg" alt="Nội dung cần trả phí" />
          </div>
          <div class="quiz-subtitle">
            <span class="quiz-subtitle-item">{{item.class_name}} &#8226; {{item.category_name}}</span>
            <span class="quiz-subtitle-item"><img src="/icon/library/time.svg" /> {{ item.time }} phút</span>
            <span class="quiz-subtitle-item"><img src="/icon/library/question.svg" /> {{ item.number_question }} câu</span>
          </div>
          <div class="quiz-source" v-if="item.author">
            Nguồn: {{ item.author }}
          </div>
          <!-- <div class="quiz-source">{{ item.number_people }} người đã làm</div> -->
        </div>
        <div class="action quiz-action" v-if="!action">
          <template v-if="!user">
            <div class="text-center">
              <img src="/icon/library/save-video.svg" class="save-video" v-b-modal.login/>
              <div class="sub-title">Lưu</div>
            </div>
          </template>
          <template v-if="item.is_saved">
            <div class="text-center">
              <img src="/icon/library/save-video-active.svg" class="save-video" @click.prevent="redirectDetail"/>
              <span class="sub-title saved">Đã lưu</span>
            </div>
          </template>
          <template v-else>
            <div class="text-center">
              <img src="/icon/library/save-video.svg" class="save-video" @click.prevent="redirectDetail"/>
              <span class="sub-title">Lưu</span>
            </div>
          </template>
        </div>
        <div class="action quiz-action d-flex align-items-center" v-else>
          <template v-if="!user">
            <div class="text-center">
              <img src="/icon/library/save-video.svg" class="save-video" v-b-modal.login/>
              <div class="sub-title">Lưu</div>
            </div>
          </template>
          <template v-else-if="item.is_saved">
            <div class="text-center">
              <img src="/icon/library/save-video-active.svg" class="save-video" @click.prevent="UnSave"/>
              <div class="sub-title saved">Đã lưu</div>
            </div>
          </template>
          <b-dropdown no-caret v-else :id="'dropdown-save-quiz-'+item.id" :ref="'dropdownSaveQuiz'+item.id" right variant="tranparent p-0" class="dropdown-save-quiz">
            <template #button-content>
              <div class="text-center">
                <img src="/icon/library/save-video.svg" class="save-video"/>
                <div class="sub-title">Lưu</div>
              </div>
            </template>
            <div class="title">Lưu vào thư viện</div>
            <img class="close" src="/icon/library/close.svg" @click.prevent="$refs['dropdownSaveQuiz'+item.id].hide(true)"/>
            <div class="row row-custom row-padding-15 playlist-quiz scrollbar">
              <div class="col-12" v-for="value in playlist" :key="value.id">
                <b-form-checkbox v-model="playlistId" :value="value.id" >{{value.name}}</b-form-checkbox>
              </div>
            </div>
            <div class="row row-custom row-padding-15 playlist-quiz">
              <div class="col-12 new-playlist">
                Tạo danh sách mới
              </div>
              <div class="col-12">
                <input class="playlist-name form-control" placeholder="Tên danh sách" v-model="playlistName"/>
                <div v-if="textError" class="text-error">{{textError}}</div>
              </div>
            </div>
            <div class="action-new-playlist">
              <button class="btn" @click.prevent="createPlaylist">Tạo danh sách</button>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const playlistExamResource = new Resource('library/playlist/exam')
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    playlist: {
      type: Array,
      default () {
        return []
      }
    },
    slug: {
      type: String,
      default () {
        return ''
      }
    },
    viewList: {
      type: Boolean,
      default () {
        return true
      }
    },
    action: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      playlistName: '',
      playlistId: '',
      textError: '',
      text: '',
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    playlistId () {
      if (this.playlistId) {
        if (this.processing) { return }
        const playlist = this.playlist.find(item => item.id === this.playlistId)
        if (!playlist) {
          this.text = 'Playlist không tồn tại'
          this.showNotify()
          return
        }
        this.processing = true
        const form = {
          exam_id: this.item.id,
          playlist_id: this.playlistId
        }
        playlistExamResource.store(form)
          .then((res) => {
            if (res.status === 200) {
              this.text = 'Đã lưu vào ' + playlist.name
              // eslint-disable-next-line vue/no-mutating-props
              this.item.is_saved = true
              this.showNotify()
              this.$refs['dropdownSaveQuiz' + this.item.id].hide(true)
            }
          })
          .catch((err) => {
            this.textError = err.response.data.message
          })
          .finally(() => {
            this.playlistId = ''
            this.processing = false
          })
      }
    },
    'item.is_saved' () {
      if (!this.item.is_saved) {
        this.text = 'Đã bỏ lưu ' + this.item.name
        this.showNotify()
      }
    }
  },
  methods: {
    createPlaylist () {
      this.playlistName = this.playlistName.trim()
      if (this.processing) { return }
      if (!this.playlistName) {
        this.textError = '*Vui lòng nhập tên danh sách'
        return
      }
      if (this.playlistName.length > 100) {
        this.textError = 'Tên danh sách tối đa 100 ký tự'
        return
      }
      if (this.$helper.specialCharacters(this.playlistName)) {
        this.textError = 'Tên danh sách không được chứa ký tự đặc biệt'
        return
      }
      this.textError = ''
      this.processing = true
      const form = {
        exam_id: this.item.id,
        playlist_name: this.playlistName
      }
      playlistExamResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_saved = true
            this.text = 'Đã lưu vào ' + this.playlistName
            this.playlistName = ''
            this.showNotify()
            this.$emit('createdPlaylist')
            this.$refs['dropdownSaveQuiz' + this.item.id].hide(true)
          }
        })
        .catch((err) => {
          this.textError = err.response.data.message
        })
        .finally(() => {
          this.processing = false
        })
    },
    UnSave () {
      if (this.processing) { return }
      playlistExamResource.destroy(this.item.id)
        .then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_saved = false
          this.$emit('createdPlaylist')
        })
        .finally(() => {
          this.processing = false
        })
    },
    showNotify () {
      this.$emit('showNotify', this.text)
    },
    redirectDetail () {
      // if (!this.user) {
      //   this.$bvModal.show('login')
      //   return
      // }
      if (this.slug) {
        this.$helper.checkUserBuySub(this, this.item, '/thu-vien-cua-toi/de-thi/' + this.slug + '/' + this.item.slug)
      } else {
        this.$helper.checkUserBuySub(this, this.item, '/trac-nghiem/' + this.item.slug)
      }
    }
  }
}
</script>

<style lang="scss">
  .quiz-item{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(174, 174, 174, 0.34);
    border-radius: 8px;
    padding:20px 14px;
    &:hover{
      box-shadow: 0px 4px 6px 2px rgba(64, 134, 255, 0.4);
      .quiz-name{
        color:var(--primary);
      }
    }
    .label-item{
      position: absolute;
      top: 0px;
      left: -3px;
    }
    .sub-title{
      padding-left:5px;
    }
    .quiz-name{
      font-weight: 500;
      font-size: 18px;
      color: #262F3E;
      min-height:54px;
    }
    .quiz-line{
      margin-top:10px;
      margin-bottom:16px;
      height: 0;
      border: 0;
      border-top: 1px solid #E2E2E2;
    }
    .quiz-subtitle{
      font-size: 14px;
      color: #262F3E;
    }
    .quiz-source{
      padding-top:16px;
      font-style: italic;
      font-size: 14px;
      color: #75777B;
      min-height: 31px;
    }
  }
  .quiz-item-detail{
    &:hover{
      box-shadow: 0px 4px 6px 2px rgba(64, 134, 255, 0.4);
      .document-name{
        color:var(--primary);
      }
    }
    .sub-title{
      padding-left:0px;
      margin-top: 5px;
    }
    .form-media-data{
      background: #FFFFFF;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      padding:1rem;
      .lock{
        margin-left:10px;
        width:16px;
      }
      .content{
        .quiz-name{
          font-weight: 500;
          font-size: 18px;
          color: #262F3E;
        }
        .quiz-subtitle{
          font-size: 14px;
          color: #364560;
          margin-top: 10px;
          .quiz-subtitle-item{
            margin-right: 50px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .quiz-source{
          margin-top: 5px;
          font-style: italic;
          font-size: 14px;
          color: #364560;
        }
      }
      .action{
        min-width: 35px;
      }
    }
  }
  .quiz-action{
    .save-video{
      cursor: pointer;
    }
    .sub-title{
      font-size: 14px;
      color: #262F3E;
      &.saved{
        color:var(--primary);
      }
    }
    .dropdown-save-quiz{
      // z-index:99;
      ul{
        padding:24px;
        width: 328px;
        background: #FFFFFF;
        box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        .title{
          font-weight: 500;
          font-size: 18px;
          color: #0A152A;
          padding-bottom:10px;
        }
        .close{
          position: absolute;
          cursor: pointer;
          width:12px;
          height:12px;
          top:16px;
          right:16px;
        }
        .playlist-quiz{
          &:first-child{
            max-height:150px;
          }
          overflow: auto;
          label{
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            color: #0A152A;
            padding-left: 10px;
            cursor: pointer;
            word-break: break-word !important;
            word-wrap: break-word !important;
            &::before,&::after{
              width: 20px;
              height: 20px;
              top:0;
            }
          }
          .new-playlist{
            font-size: 16px;
            color: #0A152A;
          }
          .playlist-name{
            background: #E8ECF5;
            border-radius: 4px;
            font-size: 16px;
            height:40px;
            color: #0A152A;
            border:0 !important;
          }
          .text-error{
            padding-top:8px;
            font-size: 16px;
            color: #FC4848;
          }
        }
        .action-new-playlist{
          margin-top:30px;
          margin-bottom:10px;
          button{
            width: 100%;
            background:var(--primary);
            border-radius: 20px;
            height:40px;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>
